import { Injectable, inject } from '@angular/core';
import { DriverAggregate, Partner, PartnerCompanyAggregate } from 'src/app/core/models/firestore.model';
import { FirestoreHelperService } from 'src/app/shared/services/firestore-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthFirestoreService {
  private firestoreHelper = inject(FirestoreHelperService);

  getPartnerCompanyAggregate(partnerCompanyId: string) {
    return this.firestoreHelper.getDocument<PartnerCompanyAggregate>(`partner_company_aggregate`, partnerCompanyId);
  }

  watchDriverAggregate(id: string) {
    return this.firestoreHelper.docData<DriverAggregate>(`driver_aggregate/${id}`);
  }

  watchPartner(id: string) {
    return this.firestoreHelper.docData<Partner>(`partner/${id}`);
  }

  watchPartnerCompanyAggregates(ids: string[]) {
    return this.firestoreHelper.colDataByIdsNotNull<PartnerCompanyAggregate>(`partner_company_aggregate`, ids);
  }
}
